<template>
  <div ref="pageContainerRef" class="PageContainer">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
const pageContainerRef = ref<HTMLElement>();

const { x, y } = useScroll(pageContainerRef);

const scrollPosX = ref(0);
const scrollPosY = ref(0);

watch([x, y], () => {
  scrollPosX.value = x.value;
  scrollPosY.value = y.value;
});

onActivated(() => {
  x.value = scrollPosX.value;
  y.value = scrollPosY.value;
});
</script>

<style scoped lang="scss">
.PageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  padding-top: 0px;
  overflow: hidden;
}
</style>
